/* @import "../src/components/assets/css/maps/style.css.map"; */

@import "../src/components/assets/css/style.css";

.mcq-content p {
  display: inline;
}

#accordion-style-1 h1,
#accordion-style-1 a {
  color: #007b5e;
}
#accordion-style-1 .btn-link {
  font-weight: 400;
  color: #007b5e;
  background-color: transparent;
  text-decoration: none !important;
  font-size: 16px;
  font-weight: bold;
  padding-left: 25px;
}

#accordion-style-1 .card-body {
  border-top: 2px solid #007b5e;
}

#accordion-style-1 .card-header .btn.collapsed .fa.main {
  display: none;
}

#accordion-style-1 .col-12 {
  padding: 0px !important;
}
#accordion-style-1 .card-header .btn .fa.main {
  background: #007b5e;
  padding: 13px 11px;
  color: #ffffff;
  width: 35px;
  height: 41px;
  position: absolute;
  left: -1px;
  top: 10px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  display: block;
}

.text-red {
  color: red;
}

.text-green {
  color: green;
}

.question p {
  font-weight: bolder !important;
  color: #1a1a37 !important;
  font-size: 18px !important;
}

.filter-content a {
  color: var(--paragraph-color);
}

.MuiPagination-root button {
  border-color: #9ed263 !important;
}

.course-detail-btn:hover {
  cursor: pointer;
}



