/*!------------------------------------------------------------------
[MAIN STYLESHEET]

PROJECT:	Project Name
VERSION:	Versoin Number
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[TABLE OF CONTENTS]
-------------------------------------------------------------------*/

/*  typography */

@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@font-face {
  font-family: "futura-bold";
  src: url("../fonts/Futura-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

:root {
  --primary-color: #2aaf62;
  --secondary-color: #06aaf4;
  --tertiary-color: #006eb9;
  --heading-color: #39424a;
  --paragraph-color: #7e8691;
  --white-color: #fff;
  --title-color: #1e1e4b;
  --gray-color: #f1f1f1;
}

body {
  line-height: 1.2;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  color: #5c5c77;
}

p,
.paragraph {
  font-weight: 450;
  color: #5c5c77;
  font-size: 14px;
  line-height: 1.4;
  font-family: "Poppins", sans-serif;
  text-align: justify;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--title-color);
  /* font-family: "futura-bold"; */
  font-weight: 600;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 35px;
  color: var(--title-color);
}

@media (max-width: 812px) and (min-width: 412px) {
  h1,
  .h1 {
    font-size: 30px;
  }
}

@media (max-width: 384px) {
  h1,
  .h1 {
    font-size: 30px;
  }
}

h2,
.h2 {
  font-size: 30px;
}

@media (max-width: 575px) {
  h2,
  .h2 {
    font-size: 25px;
  }
}

h3,
.h3 {
  font-size: 25px;
}

@media (max-width: 575px) {
  h3,
  .h3 {
    font-size: 20px;
  }
}

h4,
.h4 {
  font-size: 18px;
}

@media (max-width: 575px) {
  h4,
  .h4 {
    font-size: 18px;
  }
}

h5,
.h5 {
  font-size: 20px;
}

@media (max-width: 575px) {
  h5,
  .h5 {
    font-size: 16px;
  }
}

h6,
.h6 {
  font-size: 15px;
}

@media (max-width: 575px) {
  h6,
  .h6 {
    font-size: 14px;
  }
}

/* .crd-dby-dv {
  background: var(--gray-color);
} */

/* Button style */

.btn {
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  padding: 8px 40px;
  border-radius: 0;
  font-weight: 400;
  border: 0;
  position: relative;
  z-index: 1;
  transition: 0.2s ease;
  overflow: hidden;
  border-radius: 20px;
}

.btn::before {
  position: absolute;
  content: "";
  height: 80%;
  width: 100%;
  left: 0;
  bottom: 10%;
  z-index: -1;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
}

.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.btn:active {
  box-shadow: none;
}

.btn:hover::before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
}

.btn-sm {
  font-size: 14px;
  padding: 10px 35px;
}

.btn-xs {
  font-size: 12px;
  padding: 5px 15px;
}

.btn-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-primary::before {
  background: #fff;
}

.btn-primary:active {
  background: var(--primary-color) !important;
  color: var(--primary-color);
}

.btn-primary:active::before {
  height: 80%;
}

.btn-primary:hover {
  background: var(--secondary-color);
  color: var(--primary-color);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.btn-secondary {
  background: #fff;
  color: var(--primary-color);
  border: 1px solid #fff;
}

.btn-secondary::before {
  background: var(--primary-color);
}

.btn-secondary:active {
  background: var(--primary-color);
  color: #fff;
  border: 1px solid #fff;
}

.btn-secondary:hover {
  background: #fff;
  color: #fff;
  border: 1px solid #fff;
}

.btn-primary-outline {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  background: transparent;
}

.btn-primary-outline::before {
  background: #fff;
}

.btn-primary-outline:hover {
  background: var(--primary-color);
  color: var(--primary-color);
}

.btn-primary-outline:active {
  background: var(--primary-color);
  color: #fff;
}

.btn-facebook {
  background: #3b5998;
}

.btn-google {
  background: #ea4335;
}

.btn-apple {
  background: #a2aaad;
}

.btn-esewa {
  background: #41a124;
}

.btn-connectips {
  background: #eb0026;
}

body {
  background-color: #fff;
  overflow-x: hidden;
}

::-moz-selection {
  background: #ffcd6e;
  color: #fff;
}

::selection {
  background: #ffcd6e;
  color: #fff;
}

/* preloader */

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffdc11;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

ol,
ul {
  list-style-type: none;
  margin: 0px;
}

img {
  vertical-align: middle;
  border: 0;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a,
button,
select {
  cursor: pointer;
  transition: 0.2s ease;
}

a:focus,
button:focus,
select:focus {
  outline: 0;
}

a:hover {
  color: var(--primary-color);
}

a.text-primary:hover {
  color: var(--primary-color) !important;
}

a.text-light:hover {
  color: var(--primary-color) !important;
}

h4 {
  transition: 0.2s ease;
}

a h4:hover {
  color: var(--primary-color);
}

.slick-slide {
  outline: 0;
}

.section {
  padding-top: 90px;
  padding-bottom: 50px;
}

.section1 {
  padding-top: 90px;
}
.section2 {
  padding-top: 60px;
}
.section-sm {
  padding-top: 40px;
  padding-bottom: 40px;
}

.section-title {
  margin-bottom: 30px;
}

.bg-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.border-primary {
  border-color: #ededf1 !important;
}

/* overlay */

.home-back-img {
  background-image: url(/public/srtbackground.jpg);
  height: 100%;
  background-size: cover;
}

.overlay {
  position: relative;
}

.banner-div {
  width: 100%;
}
.banner-overlay {
  position: relative;
  /* content: ""; */
  height: 100%;
  width: 100%;
  /* background: #1a1a37; */
  opacity: 0.7;
  z-index: -1;
}
.carousel-item img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 812px) {
  .carousel-item img {
    height: 100%;
    padding-top: 7rem;
  }
}
.banner-content {
  position: absolute;
  top: 45%;
  left: 20;
}
.carousel-control-prev-icon {
  position: absolute;
  top: 70%;
  left: 450%;
}
.carousel-control-next-icon {
  top: 70%;
  position: absolute;
  left: -90%;
  font-weight: bold;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .carousel-control-next-icon {
    top: 80% !important;
  }
  .carousel-control-prev-icon {
    top: 80% !important;
    left: 425%;
  }
  .banner-content {
    left: 15% !important;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .carousel-control-next-icon {
    top: 80% !important;
  }
  .carousel-control-prev-icon {
    top: 80% !important;
    left: 425%;
  }
  .banner-content {
    left: 15% !important;
  }
}
@media only screen and (max-width: 300px) {
  .carousel-control-next-icon {
    top: 80% !important;
  }
  .carousel-control-prev-icon {
    top: 80% !important;
    left: 425%;
  }
  .banner-content {
    left: 15% !important;
  }
}
/* .banner-overlay::before{
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #1a1a37;
  opacity: 0.8;
  z-index: -1;
} */
.overlay::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: var(--white-color);
  opacity: 0.8;
}

.outline-0 {
  outline: 0 !important;
}

.d-unset {
  display: unset !important;
}

.bg-primary {
  background: var(--primary-color) !important;
}

.bg-secondary {
  background: #1a1a37 !important;
}

.bg-gray {
  background: #f8f8f8;
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-color {
  color: #5c5c77;
}

.text-light {
  color: #8585a4 !important;
}

.text-lighten {
  color: #d6d6e0 !important;
}

.text-muted {
  color: #b5b5b7 !important;
}

.text-dark {
  color: #1e1e4b !important;
}

.font-secondary {
  font-family: "futura-bold";
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.pl-150 {
  padding-left: 150px;
}

.zindex-1 {
  z-index: 1;
}

@media (max-width: 991px) {
  .overflow-md-hidden {
    overflow: hidden;
  }
}

.vertical-align-middle {
  vertical-align: middle;
}

.icon-md {
  font-size: 36px;
}

/* page title */

.page-title-section {
  padding: 200px 0 80px;
}

.custom-breadcrumb li.nasted {
  position: relative;
  padding-left: 25px;
}

.custom-breadcrumb li.nasted::before {
  position: absolute;
  font-family: "themify";
  content: "\e649";
  font-size: 20px;
  top: 50%;
  left: -5px;
  color: var(--paragraph-color);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* /page title */

.list-styled {
  padding-left: 25px;
}

.list-styled li {
  position: relative;
  margin-bottom: 15px;
}

.list-styled li::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: var(--primary-color);
  left: -25px;
  top: 5px;
}

textarea.form-control {
  height: 200px;
  padding: 20px;
}

#map_canvas {
  height: 500px;
}

.top-header {
  font-size: 12px;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
  -webkit-transform-origin: top;
  transform-origin: top;
  font-weight: 600;
}

.top-header.hide {
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: top;
  transform-origin: top;
}

/* .navigation {
    background: linear-gradient( to right, transparent 50%, var(--primary-color) 50%);
    transition: 0.2s ease;
} */

.navbar-logo {
  height: 55px;
  overflow: hidden;
  float: left;
}

.navbar-logo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.navbar-brand span {
  height: 75px;
  display: flex;
  align-items: center;
  font-size: 44px;
  padding-left: 20px;
}

/* @media (max-width: 991px) {
  .navigation {
    background: #1a1a37;
  }
} */

@media (max-width: 991px) {
  .navbar-collapse {
    background: #1a1a37;
  }
}

/* .navbar-nav {
    padding-left: 50px;
    background: var(--primary-color);
} */

@media (max-width: 991px) {
  .navbar-nav {
    padding-left: 0;
    background: white;
  }
}

/* .nav-bg {
    background-color: #1a1a37;
} */

.nav-item {
  margin: 0 15px;
  position: relative;
}

.nav-item .nav-link {
  text-transform: uppercase;
  font-weight: 600;
}

.nav-item::before {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  content: "";
  background: var(--title-color);
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: top;
  transform-origin: top;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.nav-item:hover::before,
.nav-item.active::before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
}

.navbar-dark .navbar-nav .nav-link {
  color: var(--title-color);
}

link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: var(--primary-color);
}

.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: var(--title-color);
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding: 9px 0px;
}

@media (max-width: 991px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 20px;
  }
}

/* .sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  background: #fff;
  box-shadow: 0 2px 5px #0000000d;
} */

.navbar .dropdown:hover .dropdown-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.navbar .dropdown-menu {
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.12);
  border-bottom: 5px solid var(--primary-color);
  padding: 15px;
  top: 96px;
  border-radius: 0;
  display: block;
  visibility: hidden;
  transition: 0.3s ease;
  opacity: 0;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  background: #fff;
}

@media (max-width: 991px) {
  .navbar .dropdown-menu {
    display: none;
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: unset;
    transform-origin: unset;
  }
}

.navbar .dropdown-menu.show {
  visibility: hidden;
}

@media (max-width: 991px) {
  .navbar .dropdown-menu.show {
    visibility: visible;
    display: block;
  }
}

.navbar .dropdown-item {
  position: relative;
  color: #1e1e4b;
  transition: 0.2s ease;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
}

@media (max-width: 991px) {
  .navbar .dropdown-item {
    text-align: center;
  }
}

.navbar .dropdown-item:not(:last-child) {
  margin-bottom: 10px;
}

.navbar .dropdown-item:hover {
  color: var(--primary-color);
  background: transparent;
}

/* .hero-section {
  padding: 150px 0 200px;
} */

.hero-slider .slick-prev,
.hero-slider .slick-next {
  position: absolute;
  bottom: -123px;
  z-index: 9;
  padding: 15px;
  color: rgba(255, 255, 255, 0.5);
  border: 0;
  font-size: 30px;
  transition: all linear 0.2s;
  background: transparent;
}

.hero-slider .slick-prev:focus,
.hero-slider .slick-next:focus {
  outline: 0;
}

.hero-slider .slick-prev:hover,
.hero-slider .slick-next:hover {
  color: var(--primary-color);
}

.hero-slider .slick-prev {
  right: 60px;
}

.hero-slider .slick-next {
  right: 0;
}

.hero-slider .slick-dots {
  position: absolute;
  left: 0;
  bottom: -100px;
  padding-left: 0;
}

.hero-slider .slick-dots li {
  display: inline-block;
  margin: 0 6px;
}

.hero-slider .slick-dots li.slick-active button {
  background: var(--primary-color);
}

.hero-slider .slick-dots li button {
  color: transparent;
  padding: 0;
  overflow: hidden;
  height: 10px;
  width: 10px;
  background: rgba(255, 255, 255, 0.5);
  border: 0;
  outline: 0;
}

/* banner feature */

.feature-icon {
  font-size: 50px;
  color: var(--primary-color);
  display: inline-block;
}

.feature-blocks {
  margin-top: -100px;
  padding-left: 70px;
  padding-top: 80px;
  padding-right: 30%;
}

@media (max-width: 1400px) {
  .feature-blocks {
    padding-right: 10%;
  }
}

@media (max-width: 1200px) {
  .feature-blocks {
    padding-right: 50px;
    padding-left: 50px;
    padding-top: 30px;
  }
  .feature-blocks h3 {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .feature-blocks {
    margin-top: 0;
    padding: 50px;
  }
  .feature-blocks h3 {
    font-size: 25px;
  }
}

/* /banner feature */

/* course */

.topic-badge {
  background-color: var(--tertiary-color);
  color: var(--white-color);
  /* margin-bottom: 5px; */
}

.rated {
  color: orange;
}

.card-btn {
  font-size: 12px;
  padding: 5px 10px;
}

.card-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.flex-basis-33 {
  flex-basis: 33.3333%;
}

.hover-shadow {
  transition: 0.3s ease;
}

.hover-shadow:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* /course */

/* success story */

.success-video {
  min-height: 300px;
}

.success-video .play-btn {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .success-video .play-btn {
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.play-btn {
  display: inline-block;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background: var(--primary-color);
  color: #fff;
  font-size: 20px;
  text-align: center;
}

.play-btn i {
  line-height: 80px;
}

.play-btn::before {
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  z-index: -2;
  transition: 0.3s ease;
  transition-delay: 0.2s;
}

.play-btn::after {
  position: absolute;
  content: "";
  height: 80%;
  width: 80%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: var(--primary-color);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  z-index: -1;
  transition: 0.3s ease;
}

.play-btn:hover::before {
  height: 80%;
  width: 80%;
  transition-delay: 0s;
}

.play-btn:hover::after {
  height: 0;
  width: 0;
  transition: 0s ease;
}

/* /success story */

/* events */

.card-date {
  position: absolute;
  background: var(--primary-color);
  font-family: "futura-bold";
  text-align: center;
  padding: 10px;
  color: #fff;
  top: 0;
  left: 0;
  text-transform: uppercase;
  /* transform: translate(50%, 0); */
}

.card-date span {
  font-size: 40px;
}

.course-detail-btn {
  transition: all 0.2s ease-in;
}
.course-detail-btn:hover {
  /* box-shadow: 0.5px 0.5px 10px #d1d5d8; */

  transform: scale(1.08);
}

/* /events */

/* teacher */

.teacher-info {
  width: 70%;
  bottom: 0;
  right: 0;
}

/* /teacher */

/* footer */

.newsletter {
  background-image: linear-gradient(
    to right,
    transparent 50%,
    var(--primary-color) 50%
  );
  margin-bottom: -170px;
  position: relative;
  z-index: 1;
}

/* .newsletter-block {
  padding-left: 50px;
} */

@media (max-width: 575px) {
  /* .newsletter-block {
    padding-left: 15px;
  } */
}

.input-wrapper {
  position: relative;
}

.input-wrapper button {
  position: absolute;
  right: 25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.form-control {
  height: 60px;
  background: #fff;
  border-radius: 0;
  padding-left: 25px;
}

.form-control:focus {
  border-color: var(--primary-color);
  box-shadow: none;
}

.newsletter-block .form-control {
  height: 50px;
}

.bg-footer {
  background-color: #182b45;
}

.logo-footer {
  margin-top: -20px;
  display: inline-block;
}

.footer {
  border-color: #494a43 !important;
  /* padding-top: 275px; */
}

/* /footer */

.filter-controls li {
  cursor: pointer;
  transition: 0.1s ease;
}

.filter-controls li.active {
  font-weight: 600;
  color: var(--primary-color);
}

.filter-controls li:hover {
  color: var(--primary-color);
}

/*# sourceMappingURL=maps/style.css.map */

/* register css */

.login h3 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.register-form-card h3 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.register-form-card p {
  margin-top: 30px;
  text-align: center;
}

/* book detail */

.book-detail-img {
  height: 400px;
  overflow: hidden;
}

.book-detail-img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  margin-top: 3rem;
}

/* book list */

.book-list-img {
  height: 300px;
  overflow: hidden;
}

.book-list-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Discussion */

.new-discussion {
  display: flex;
  /* align-items: flex-end; */
  justify-content: end;
  /* margin-top: "10rem"; */
}

.reply-box {
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .new-discussion {
    justify-content: start;
  }
}

/* Video */

.video-thumbnail {
  position: relative;
  width: 100%;
  height: 10rem;
}

.video-thumbnail img {
  width: 100%;
  height: 100%;
}

.video-card:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: all ease-in-out 0.5s;
}

.video-overlay {
  position: absolute;
  /* Sit on top of the page content */
  display: flex;
  /* Hidden by default */
  justify-content: center;
  align-items: center;
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0);
  opacity: 0;
  z-index: 1;
}

.video-card:hover .video-overlay {
  opacity: 0.5;
  transition: all ease-in-out 0.5s;
}

.video-title {
  z-index: 99;
}

.play-icon {
  font-size: 4rem;
}

.recommendation-card {
  display: flex;
  flex-direction: row !important;
  border: none !important;
}

.recommendation-card :hover {
  cursor: pointer;
}

.recommendation-card img {
  width: 35%;
}

.recommendation-card .card-title {
  font-family: "Roboto", sans-serif;
}

.video-section {
  margin-top: 5.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.video-section .video-title {
  font-family: "Roboto", sans-serif;
  color: var(--title-color);
  font-size: 1rem;
}

.recommendation-title {
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  color: var(--title-color);
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  /* -webkit-box-orient: vertical; */
}

/* About */

.about-desc {
  text-align: justify;
}

.about-img {
  height: 300px;
  overflow: hidden;
}

.about-img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.exam-section {
  margin-top: 6rem !important;
}

@media only screen and (max-width: 812px) {
  .timer {
    margin-top: -6rem !important;
  }
}

.mcq-main-qns {
  text-align: justify;
  margin: 0 64px;
}

.mcq-content {
  margin: 26px 0;
}

.mcq-content p {
  font-family: var(--p-family);
  font-weight: bold;
}

.radio-ans {
  margin-left: 26px;
  line-height: 2rem;
}

.mcq-img {
  height: 100%;
  width: 100%;
  overflow: hidden;
  text-align: center;
  margin-bottom: 36px;
}

.radio-ans input {
  margin: 0 8px;
}

.timer {
  height: 3rem;
  width: 10rem;
  position: fixed;
  right: 50px;
  float: right;
  background-color: #182b45;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

/* ......profile-page....... */

.profilepage {
  margin-top: 54px;
}

.sidebar {
  /* background-color: var(--primary-color); */
  padding-top: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-top-right-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.link-sec-dv {
  margin-bottom: 1.5rem;
}

.link-sec-dv i {
  width: 30px;
  height: 30px;
  background: #f1f0f0;
  justify-content: center;
  display: inline-flex;
  align-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--title-color);
}

.link-sec-dv i:hover {
  color: var(--primary-color);
}

.link-sec-dv a {
  color: var(--title-color);
}

.link-sec-dv a:hover {
  color: var(--primary-color);
}

.sidenav_items {
  padding: 10px;
  border-bottom: 1px solid var(--white-color);
  text-align: center;
}

.sidenav_items h5 i {
  margin-right: 5px;
}

.sidenav_items h5 {
  color: var(--white-color);
  transition: 0.5s;
  margin: 0 !important;
  padding: 5px 0;
}

.sidenav_items h5:hover {
  color: var(--title-color);
  cursor: pointer;
}

.myprofile {
  margin: 40px 0;
}

.profile_name {
  margin-bottom: 30px;
}

.profile_name h6 {
  font-size: 25px;
}

.profile_heading h3 {
  color: var(--primary-color);
}

.top_section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit_button {
  text-align: center;
}

.profile_image {
  height: 130px;
  width: 130px;
}

.profile_image img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.edit_profile button {
  margin-bottom: 20px;
}

.mid_section {
  margin-top: 20px;
}

.mid_heading {
  text-transform: capitalize;
}

.mid_names {
  display: flex;
}

.mid_names span h6 {
  padding: 20px 20px 20px 0;
  margin-right: 50px;
}

.mid_names h6 {
  padding: 20px 20px 20px 0;
}

.edit_profile {
  margin-top: 30px;
}

.edit_profile form {
  margin-top: 40px;
}

.edit_button a .btn:hover {
  color: var(--primary-color);
}

.edit_profile .form-group {
  margin-bottom: 20px !important;
}

.course_heading {
  padding: 18px 0;
  margin-top: 2.5rem;
}

.invoice {
  margin-top: 30px;
}

.table .btn {
  padding: 3px 10px !important;
  background-color: var(--primary-color);
  border-radius: 2px;
}

.btn-jump {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.modal .modal-dialog .modal-content .modal-body .table thead {
  background-color: var(--primary-color) !important;
}

.modal-body {
  padding: 0 !important;
}

.modal-logo {
  height: 80px;
  width: 80px;
}

.modal-logo img {
  height: 100%;
  width: 100%;
}

.invoice_sender {
  margin: 30px 30px 20px 30px;
}

.invoice_sender h6 {
  font-size: 12px !important;
  font-weight: 300 !important;
  color: var(--primary-color);
}

.invoice_sender h5 {
  font-size: 13px !important;
  font-weight: 300px;
}

.invoice_heading {
  margin-bottom: 25px;
}

.modal-footer {
  justify-content: left !important;
}

.payment_method p {
  line-height: 1 !important;
  padding: 2px 0;
}

.total p {
  line-height: 1 !important;
  padding: 2px 0;
}

.total {
  margin-left: 150px;
}

.total span h6 {
  background-color: var(--primary-color);
  padding: 8px;
  border-radius: 3px;
}

.modal-dialog {
  max-width: 600px;
}

.sign {
  border-bottom: 2px solid #ebebeb;
}

.history_section {
  margin-top: 30px;
}

.mid_contents .table td {
  border: none !important;
}

.profile_footer .footer {
  padding-top: 70px !important;
}

.table {
  margin-bottom: 30px;
}

.exam_title {
  text-align: center;
}

.mcq-content .radio-ans label {
  display: inline;
}

@media only screen and (max-width: 600px) {
  .myprofile {
    text-align: center;
  }
  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .col {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .footer {
    text-align: center;
  }
  .sidenav_items {
    padding: 5px;
  }
}

@media only screen and (min-width: 280px) {
  .sidebar {
    padding-top: 20px;
  }
  .navbar-brand span {
    padding-left: 11px;
    font-size: 25px;
  }
}

/* Features css */

.Features-section {
  background: var(--gray-color);
}

.icn-feat-dv i {
  width: 130px;
  height: 130px;
  background: white;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
  font-size: 40px;
  margin-bottom: 8px;
  border: 1px solid #d9dfd9;
}

.tst-mol-img {
  width: auto;
  height: auto;
  object-fit: contain;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.tst-mol-img img {
  width: 100%;
  height: 100%;
}

.srt-gk-img {
  height: 35rem;
  object-fit: contain;
}

.srt-gk-img img {
  width: 100%;
  height: 100%;
}

@media only scree and(max-width:812) {
  .srt-gk-img {
    height: 22rem;
  }
}

.overlay-prt {
  position: absolute;
  top: 0;
  left: 14px;
  right: 14px;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  background: var(--primary-color);
  cursor: pointer;
}

.txt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white !important;
  text-align: center;
}
@media only screen and (max-width: 810px) and (min-width: 360px) {
  .tst-mol-img:hover .overlay-prt {
    opacity: 0.8 !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
  }
}

.tst-mol-img:hover .overlay-prt {
  opacity: 0.8;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.overlay-dv {
  position: absolute;
  top: 0;
  left: 14px;
  right: 14px;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  background: black;
  cursor: pointer;
}

.trd-txt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white !important;
  text-align: center;
}

.tst-mol-img:hover .overlay-dv {
  opacity: 0.8;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.tst-mols-txt {
  padding: 4rem;
}

.tst-mols-txt h6 {
  line-height: 2.5rem;
}

.tst-mols-txt h6 i {
  color: var(--primary-color);
  padding-right: 1rem;
}

.view-all {
  text-align: center;
  color: var(--paragraph-color);
  margin-top: 1rem;
  font-size: 18px;
}

.view-all:hover {
  color: var(--primary-color);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: var(--primary-color);
}

@media only screen and (max-width: 810px) {
  .tst-mol-img {
    margin-bottom: 2rem;
  }
  .tst-mols-txt {
    padding: 0;
  }
  .icn-feat-dv {
    margin-bottom: 1.5rem;
  }
  /* .hero-section {
    padding: 130px 0 110px;
  } */
  .overlay-prt {
    bottom: 30px;
  }
  .overlay-dv {
    bottom: 30px;
  }
}

.text-white {
  color: var(--paragraph-color) !important;
}

.text-course {
  color: white;
}

.card-img {
  width: 100%;
  height: 250px;
}

.card-img img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

.Nav-sec {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

.playstore-img {
  height: 70px;
  overflow: hidden;
  margin-left: -0.5rem;
}

.playstore-img img {
  height: 100%;
  object-fit: contain;
}

.login-from-dv {
  width: 40%;
  margin: auto;
}

@media only screen and (max-width: 810px) {
  .login-from-dv {
    width: 100%;
  }
}

@media only screen and (max-width: 990px) {
  .section {
    padding-top: 30px !important;
  }
}

.bar {
  color: var(--primary-color);
  font-size: 28px;
}

@media only screen and (max-width: 574px) {
  .navbar-dark .navbar-brand {
    margin-left: 8px;
  }
  .book-detail-img img {
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 100% !important;
  }
}

.user-card-full {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
  border: none !important;
}

@media only screen and (max-width: 766px) {
  .user-card-full {
    margin-top: 3rem;
  }
}

.owl-carousel .owl-prev {
  /* background:var(--primary-color) !important; */
  width: 35px !important;
  height: 35px !important;
  border-radius: 25px !important;
  color: var(--primary-color) !important;
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: absolute !important;
  left: -50px !important;
  top: 35% !important;
  border: 1px solid var(--primary-color) !important;
}

.owl-carousel .owl-prev span {
  font-size: 35px !important;
  margin-top: -4px;
}

.owl-carousel .owl-next {
  /* background:var(--primary-color) !important; */
  width: 35px !important;
  height: 35px !important;
  border-radius: 25px !important;
  color: var(--primary-color) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: absolute !important;
  right: -50px !important;
  top: 35% !important;
  border: 1px solid var(--primary-color) !important;
}

.owl-carousel .owl-next span {
  font-size: 35px !important;
  margin-top: -4px;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background: var(--primary-color) !important;
  color: white !important;
  border: none !important;
  transition: ease-in !important;
}

@media only screen and (max-width: 598px) {
  .owl-carousel .owl-prev {
    left: 0 !important;
  }
  .owl-carousel .owl-next {
    right: 0 !important;
  }
}

.testi-mol-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testi-mol-img img {
  width: 100%;
  height: 100%;
}

.icon-stl-sec {
  width: 30px;
  height: 30px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 20px;
}

.icon-stl-sec:hover {
  background: rgb(252, 250, 250);
  border: 0.5px solid var(--primary-color);
}

.crsdtl {
  font-size: 20.7px;
}

.ps-crs-img {
  width: 200px;
  height: 125px;
  object-fit: cover;
}

.ps-crs-img img {
  height: 100%;
  width: 100%;
}
